<template>
  <div class="document-request-fi-show-step2">
    <v-card class="mt-30 mb-8" outlined v-if="facilityInterestedOption1.length">
      <v-card-title class="justify-space-between bg-title-main-bg-optional pa-0 pl-3">
        <span class="d-custom-title-form">{{ $t('documentRequest.facilityInterestedTitleOptional1') }}</span>
        <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
          {{ $t('common.labelOptional') }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="3" v-for="(item, i) in facilityInterestedOption1" :key="i" :class="['d-border-wap']">
            <p class="ma-0 d-flex flex-wrap">{{ item }}</p>
          </v-col>
          <template v-if="facilityInterestedOption1.length % 4">
            <v-col v-for="n of (4 - facilityInterestedOption1.length % 4)" :key="`nk-${n}`" cols="12" sm="6" md="3" class="d-border-wap d-hidden-item"></v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-30 mb-8" outlined v-if="facilityInterestedOption2.length">
      <v-card-title class="justify-space-between bg-title-main-bg-optional pa-0 pl-3">
        <span class="d-custom-title-form">{{ $t('documentRequest.facilityInterestedTitleOptional2') }}</span>
        <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
          {{ $t('common.labelOptional') }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="3" v-for="(item, i) in facilityInterestedOption2" :key="i" :class="['d-border-wap']">
            <p class="ma-0 d-flex flex-wrap">{{ item }}</p>
          </v-col>
          <template v-if="facilityInterestedOption2.length % 4">
            <v-col v-for="n of (4 - facilityInterestedOption2.length % 4)" :key="`nk-${n}`" cols="12" sm="6" md="3" class="d-border-wap d-hidden-item"></v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import createWebRequest from '@/mixins/createWebRequest'

export default {
  name: 'FacilityInterestedShow',
  mixins: [
    createWebRequest
  ],
  data () {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
  },
  computed: {
  },
  created () {
    this.typeApply = this.type
  }
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
