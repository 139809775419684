<template>
  <v-card class="responsive-base-card-rm" outlined>
    <v-card-title class="justify-space-between">
      <span class="label-main-title">{{ $t('documentRequest.client.reqForMemberTitle') }}</span>
      <v-chip class="ma-2 d-label-required-bg" label text-color="white">
        {{ $t('common.labelRequired') }}
      </v-chip>
    </v-card-title>
    <v-card-subtitle>
      <span v-if="typeValid === 'REQ_CHECKBOX_RFM'" class="error--text">Required</span>
    </v-card-subtitle>
    <v-card-text>
      <v-row class="ml-2">
        <v-radio-group class="flex-fill justify-space-between" row v-model="vModelCheckReqForMember">
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.value">
            <v-radio
              :label="item.text"
              :value="item.value"
              hide-details
              :rules="rules"
              @change="onClickChangeReqForMember"
            />
          </v-col>
        </v-radio-group>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import C from '@/utils/constant'

export default {
  name: 'RequestForMember',
  data () {
    return {
      items: [],
      vModelCheckReqForMember: ''
    }
  },
  props: {
    typeValid: {
      type: String,
      required: false
    }
  },
  model: {
    event: 'eventChangeRequestForMember'
  },
  mounted () {
    this.items = this.initItems()
    this.$nextTick(() => {
      this.vModelCheckReqForMember = this.dataClientDocRequest.requestForMember?.value
      this.onClickChangeReqForMember()
    })
  },
  methods: {
    initItems () {
      return [{
        text: this.$t('documentRequest.client.reqForMemberData.primeMember'),
        value: this.constant.WEB_REQUEST_FOR_TYPE_MEMBER.primeMember
      }, {
        text: this.$t('documentRequest.client.reqForMemberData.vacationsClubMember'),
        value: this.constant.WEB_REQUEST_FOR_TYPE_MEMBER.vacationsClubMember
      }, {
        text: this.$t('documentRequest.client.reqForMemberData.bothHope'),
        value: this.constant.WEB_REQUEST_FOR_TYPE_MEMBER.bothHope
      }]
    },
    onClickChangeReqForMember () {
      this.$nextTick(() => {
        const find = this.items.find(ele => ele.value === this.vModelCheckReqForMember)
        this.$emit('eventChangeRequestForMember', (find || {}))
      })
    }
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      dataClientDocRequest: state => state.documentRequest.dataClientDocRequest
    }),
    constant () {
      return Object.assign({}, C)
    },
    rules () {
      return [
        (this.vModelCheckReqForMember && Object.keys(this.vModelCheckReqForMember).length > 0) || this.$t('rules.selected')
      ]
    }
  }
}
</script>

<style lang="scss">
.responsive-base-card-rm {
  border: 2px solid #C2AD37 !important;
  margin-bottom: 68px;
}
</style>
