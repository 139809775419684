<template>
  <div class="document-request-fav-show-step2">
    <v-card class="mt-30 mb-8" outlined>
      <v-card-title class="justify-space-between bg-title-main pa-0 pl-3">
        <span class="d-custom-title-form">{{ $t('documentRequest.findAboutVacationsTitle') }}</span>
        <v-chip class="ma-2 d-label-required-bg" label text-color="white">
          {{ $t('common.labelRequired') }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row class="ma-0 bd-from-bottom">
          <v-col cols="12" sm="6" md="3" v-for="(item, n) in medias()" :key="`n-${n}`" class="d-border-wap">
            <p class="ma-0 d-flex flex-wrap">
              {{ item.mediaType }}
              <template v-if="item.mediaOther">
                ({{item.mediaOther}})
              </template>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import createWebRequest from '@/mixins/createWebRequest'

export default {
  name: 'FindAboutVacationShow',
  mixins: [
    createWebRequest
  ],
  data () {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
  },
  computed: {
  },
  created () {
    this.typeApply = this.type
  }
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
