<template>
  <v-card class="mt-8">
    <v-card-title class="ma-0 pa-2">
      <v-col cols="12" md="12" sm="12" class="bg-main-from d-flex justify-space-between align-center">
        <span class="d-label-left pl-3">{{ $t('documentRequest.client.reqForMemberTitle') }}</span>
        <v-chip class="ma-2 d-label-required-bg" label text-color="white">
          {{ $t('common.labelRequired') }}
        </v-chip>
      </v-col>
    </v-card-title>
    <v-card-text class="ma-0 pa-0">
      <v-row class="ma-0 pa-3 pl-6">
        <p class="ma-0 d-flex flex-wrap">{{ text }}</p>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>
