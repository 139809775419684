<template>
  <div class="b-box-interested">
    <v-col class="ml-2">
      <h2>{{ titleFacilityInterested }}</h2>
    </v-col>

    <v-card class="responsive-base-card-fi1" outlined>
      <v-card-title class="justify-space-between bg-title-main-bg-optional pa-0 pl-3">
        <span class="d-custom-title-form">{{ $t('documentRequest.facilityInterestedTitleOptional1') }}</span>
        <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
          {{ $t('common.labelOptional') }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row class="ma-0" v-if="facilityInterestedList">
          <v-col cols="12" sm="6" md="3" v-for="item in facilityInterestedList" :key="item.id" class="d-border-wap">
            <v-checkbox
              class="ma-0"
              v-model="vModelCheckReqForMemberOptional1"
              :label="item.name"
              :value="item.name"
              return-object
              hide-details
              @change="onClickChangeReqForMember"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" class="d-border-wap d-hidden-item"></v-col>
          <v-col cols="12" sm="6" md="3" class="d-border-wap d-hidden-item"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="responsive-base-card-fi2" outlined>
      <v-card-title class="justify-space-between bg-title-main-bg-optional pa-0 pl-3">
        <span class="d-custom-title-form">{{ $t('documentRequest.facilityInterestedTitleOptional2') }}</span>
        <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
          {{ $t('common.labelOptional') }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="3" v-for="item in itemsOptional2" :key="item.value" :class="['d-border-wap']">
            <v-checkbox
              class="ma-0"
              v-model="vModelCheckReqForMemberOptional2"
              :label="item.name"
              :value="item.name"
              hide-details
              @change="onClickChangeReqForMember"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" class="d-border-wap d-hidden-item"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FacilityInterested',
  data () {
    return {
      itemsOptional2: [
        {
          name: '春',
          value: 'spring'
        }, {
          name: '夏',
          value: 'summer'
        }, {
          name: '秋',
          value: 'autumn'
        }, {
          name: '冬',
          value: 'winter'
        }, {
          name: 'GW',
          value: 'gw'
        }, {
          name: 'お盆',
          value: 'obon'
        }, {
          name: '年末年始',
          value: 'new_year_holiday_season'
        }
      ],
      vModelCheckReqForMemberOptional1: [],
      vModelCheckReqForMemberOptional2: []
    }
  },
  props: {
    titleFacilityInterested: {
      type: String,
      required: true
    },
    dataModel1: {
      type: Array,
      required: false
    },
    dataModel2: {
      type: Array,
      required: false
    }
  },
  model: {
    event: 'eventChangeFacilityInterested'
  },
  mounted () {
    this.$nextTick(() => {
      if (this.dataModel1 && this.dataModel1.length) {
        this.vModelCheckReqForMemberOptional1 = this.dataModel1
      }
      if (this.dataModel2 && this.dataModel2.length) {
        this.vModelCheckReqForMemberOptional2 = this.dataModel2
      }
      this.onClickChangeReqForMember()
    })
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      facilityInterestedList: state => state.main.facilityInterestedList
    })
  },
  methods: {
    onClickChangeReqForMember () {
      this.$emit('eventChangeFacilityInterested', {
        reqForMemberOptional1: this.vModelCheckReqForMemberOptional1,
        reqForMemberOptional2: this.vModelCheckReqForMemberOptional2
      })
    }
  },
  created () {
    this.$store.dispatch('fetchFacilityInterRestedListDispatch', { apolloClient: this.$apollo, toast: this.$toast })
  }
}
</script>

<style lang="scss">
.b-box-interested {
 margin-top: 30px;
}
</style>
