<template>
  <div class="d-box-find-about-vacation">
    <v-col class="ml-2">
      <h2>{{ titleFindAboutVacation }}</h2>
    </v-col>

    <v-card class="responsive-base-card-fmv " outlined>
      <v-card-title class="justify-space-between bg-title-main pa-0 pl-3">
        <span class="d-custom-title-form">{{ $t('documentRequest.findAboutVacationsTitle') }}</span>
        <v-chip class="ma-2 d-label-required-bg" label text-color="white">
          {{ $t('common.labelRequired') }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row class="ma-0">
          <v-col cols="12" class="d-border-wap">
            <v-checkbox
              v-model="vModelCheckFindAboutVacations"
              :label="internetSearch.text"
              :value="internetSearch"
              :rules="rules"
              hide-details
              @change="onClickChangeFindAboutVacation"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 d-box-sns d-border-wap">
          <v-col cols="12" sm="6" md="3">
            <v-checkbox
              v-model="vModelCheckFindAboutVacations"
              :label="checkFindAboutVacationSNS.text"
              :value="checkFindAboutVacationSNS"
              :rules="[...noCheckFindAboutVacationSNSRules, ...rules]"
              hide-details
              @change="onClickChangeFindAboutVacationSNS"
            />
          </v-col>
          <v-col cols="11" sm="6" md="3" v-for="item in items1" :key="item.value" class="">
            <v-checkbox
              v-model="vModelCheckFindAboutVacationSNS"
              :label="item.text"
              :value="item"
              class="pd-sp-left"
              :disabled="!hasCheckItemSNS"
              :rules="findAboutVacationSNSRules"
              hide-details
              @change="onClickChangeFindAboutVacationSNS"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="3" v-for="item in items2" :key="item.value" class="d-border-wap">
            <v-checkbox
              v-model="vModelCheckFindAboutVacations"
              :label="item.text"
              :value="item"
              hide-details
              :rules="rules"
              @change="onClickChangeFindAboutVacation"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 d-box-other-input">
          <v-col cols="12" sm="4" md="3" class="align-center d-flex">
            <v-checkbox
              v-model="vModelCheckFindAboutVacations"
              :label="itemOther.text"
              :value="itemOther"
              hide-details
              :rules="[...rules, ...notDataInputOtherRules]"
              @change="onClickChangeOther"
            />
          </v-col>
          <v-col cols="12" sm="8" md="9">
            <div class="pd-sp-left">
              <label>その他とお答えいただいた方は、具体的な内容をご入力ください。（必須）</label>
            </div>
            <v-text-field
              class="mt-2 pd-sp-left"
              @input="onClickChangeOther"
              @blur="onClickChangeOther"
              :disabled="!hasItemInputOther"
              label=""
              :rules="[...inputOtherRules, ...rules]"
              v-model="vModelInputOther"
              hide-details="auto"
              solo />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import C from '@/utils/constant'

export default {
  name: 'FindAboutVacations',
  data () {
    return {
      internetSearch: {
        text: 'インターネット検索',
        value: 'internet_search'
      },
      itemOther: {},
      checkFindAboutVacationSNS: {},
      items1: [
        {
          text: '公式Facebook',
          value: 'official_facebook'
        }, {
          text: '公式Instagram',
          value: 'official_instagram'
        }, {
          text: '公式Twitter',
          value: 'official_twitter'
        }
      ],
      items2: [
        {
          text: '宿泊予約サイト',
          value: 'accommodation_reservation_site'
        }, {
          text: 'テレビ',
          value: 'tv_set'
        }, {
          text: '雑誌',
          value: 'magazine'
        }, {
          text: 'DM',
          value: 'dm'
        }, {
          text: '交通広告',
          value: 'traffic_advertising'
        }, {
          text: 'ご紹介',
          value: 'Introduction'
        }, {
          text: 'JALバケーションズ',
          value: 'jal_vacations'
        }, {
          text: 'ANA',
          value: 'ana'
        }
      ],
      valueItemOther: '',
      valueFindAboutVacationSNS: '',
      vModelInputOther: '',
      vModelCheckFindAboutVacationSNS: [],
      vModelCheckFindAboutVacations: []
    }
  },
  props: {
    titleFindAboutVacation: {
      type: String,
      required: true
    },
    dataModel: {
      type: Object,
      required: false
    }
  },
  model: {
    event: 'eventChangeFindAboutVacation'
  },
  mounted () {
    if (this.dataModel && Object.keys(this.dataModel).length) {
      this.vModelInputOther = this.dataModel?.findByInputOther
      this.vModelCheckFindAboutVacationSNS = this.dataModel?.findBySNS ?? []

      if (this.dataModel?.findBySNS.length) {
        this.valueFindAboutVacationSNS = this.constant.SNS_KEY
      }

      if (this.dataModel?.findByInputOther.length) {
        this.valueItemOther = this.constant.OTHER_KEY
      }

      if (this.dataModel?.finds.length) {
        this.vModelCheckFindAboutVacations = this.dataModel?.finds
      }
    }
    this.onClickChangeFindAboutVacation()
  },
  methods: {
    onClickChangeOther () {
      const find = this.vModelCheckFindAboutVacations.find(ele => ele.value === this.constant.OTHER_KEY)
      this.valueItemOther = find ? find.value : ''

      // if (this.valueItemOther && this.vModelInputOther.length) {
      //   const tmpOther = { value: 'other', text: this.vModelInputOther }
      //   const findIndex = this.vModelCheckFindAboutVacations.findIndex(ele => ele.value === this.constant.OTHER_KEY)
      //   if (findIndex !== -1) {
      //     this.vModelCheckFindAboutVacations[findIndex].text = this.vModelInputOther
      //   } else {
      //     this.vModelCheckFindAboutVacations.push(tmpOther)
      //   }
      // } else {
      //   this.vModelCheckFindAboutVacations = this.vModelCheckFindAboutVacations.filter(ele => ele.value !== this.constant.OTHER_KEY)
      // }

      this.onClickChangeFindAboutVacation()
    },
    onClickChangeFindAboutVacationSNS () {
      const find = this.vModelCheckFindAboutVacations.find(ele => ele.value === this.constant.SNS_KEY)
      this.valueFindAboutVacationSNS = find ? find.value : ''
      this.onClickChangeFindAboutVacation()
    },
    onClickChangeFindAboutVacation () {
      this.$nextTick(() => {
        this.$emit('eventChangeFindAboutVacation', {
          data: {
            // All data in vModel
            finds: this.vModelCheckFindAboutVacations,
            findBySNS: this.vModelCheckFindAboutVacationSNS,
            findByInputOther: this.vModelInputOther
          },
          isValid: this.isValidFAV
        })
      })
    }
  },
  computed: {
    constant () {
      return Object.assign({}, C)
    },
    initItemOther () {
      return {
        text: 'その他',
        value: this.constant.OTHER_KEY
      }
    },
    initFindAboutVacationSNS () {
      return {
        text: 'SNS',
        value: this.constant.SNS_KEY
      }
    },
    hasItemInputOther () {
      return (this.valueItemOther && !!this.valueItemOther.length && this.findOther.length > 0) || this.findOther.length
    },
    hasCheckItemSNS () {
      return (this.valueFindAboutVacationSNS && !!this.valueFindAboutVacationSNS.length && this.findSNS.length > 0) || this.findSNS.length
    },
    findAboutVacationSNSRules () {
      return [
        true
        // (this.vModelCheckFindAboutVacations.length > 0 || this.vModelCheckFindAboutVacationSNS.length > 0) || this.$t('rules.selected')
      ]
    },
    notDataInputOtherRules () {
      return [
        (this.valueItemOther !== this.constant.OTHER_KEY || this.vModelInputOther.length > 0) || this.$t('rules.selected')
      ]
    },
    noCheckFindAboutVacationSNSRules () {
      return [
        true
        // (this.valueFindAboutVacationSNS !== this.constant.SNS_KEY || (this.valueFindAboutVacationSNS && this.vModelCheckFindAboutVacationSNS.length > 0)) || this.$t('rules.selected')
      ]
    },
    rules () {
      return [
        (this.vModelCheckFindAboutVacations.length > 0) || this.$t('rules.selected')
        // (this.vModelCheckFindAboutVacations.length > 0 || (this.hasCheckItemSNS && this.vModelCheckFindAboutVacationSNS.length > 0)) || this.$t('rules.selected')
      ]
    },
    inputOtherRules () {
      return [
        !!this.vModelInputOther || this.$t('rules.required', { title: this.$t('rules.data.inputOther') })
      ]
    },
    findSNS () {
      return this.vModelCheckFindAboutVacations.filter(ele => ele.value === this.constant.SNS_KEY)
    },
    findOther () {
      return this.vModelCheckFindAboutVacations.filter(ele => ele.value === this.constant.OTHER_KEY)
    },
    isValidFAV () {
      const find = this.vModelCheckFindAboutVacations.filter(ele => !(ele.value === this.constant.OTHER_KEY || ele.value === this.constant.SNS_KEY))

      const bool = find.length > 0 ? true : ((this.findSNS.length > 0 && this.vModelCheckFindAboutVacationSNS.length > 0) || (this.findOther.length > 0 && this.vModelInputOther.length > 0))
      return ![
        bool,
        (this.findSNS.length === 0 ? true : [this.vModelCheckFindAboutVacationSNS].length > 0),
        (this.findOther.length === 0 ? true : this.vModelInputOther.length > 0)
      ].some(ele => !ele)
    }
  },
  created () {
    // Init data
    this.itemOther = this.initItemOther
    this.checkFindAboutVacationSNS = this.initFindAboutVacationSNS
  }
}
</script>

<style lang="scss">
.d-box-find-about-vacation {
  margin-top: 30px;
}

.d-box-other-input {
  .v-input--is-disabled {
      .v-input__control > .v-input__slot {
        background: #EDEDED !important;
    }
  }
}
.d-box-sns {
  .v-input--is-disabled {
    .v-input--selection-controls__input {
      background: #EDEDED;
    }
  }
}
</style>
